/* globals MantleMultiLang */

var generic = generic || {};
var site = site || {};

site.client = site.client || {};

(function ($, Drupal, lazySizes, Modernizr) {
  'use strict';

  var bp = {};
  var isMobileWidth = true;
  var animationTime = 250;
  var hasTouch = !Modernizr.csspointerevents;

  Drupal.behaviors.siteHeaderV1 = {
    elems: {},
    menuAnimationQueue: false,
    timeout: false,
    options: {
      enableHover: true,
      // Our buffer for when we mouseenter between overlay / menu
      // This is for a smoother user experience
      overlayHoverCloseDelay: 260
    },
    attach: function (context) {
      var self = this;
      var $module = $('.js-site-header--v1', context);
      var $customPointer = $('.js-custom-pointer', context);
      var $page = $('html', context);
      var page_title = document.title;

      if (!$module.length) {
        return;
      }
      if ($customPointer.length) {
        $page.addClass('custom-pointer');
      }
      self.elems.$body = $('body');
      self.elems.$header = $module;
      self.elems.$dimmers = $('.js-content-dim', $module);
      self.elems.$contentDimmer = self.elems.$dimmers.filter('.js-content-dim--content');
      self.elems.$menuDimmer = self.elems.$dimmers.filter('.js-content-dim--menu');
      self.elems.$menu = $('.js-nav-menu', $module);
      self.elems.$hamburger = $('.js-nav-launch', $module);
      self.elems.$close = $('.js-nav-close', $module);
      self.elems.$closeSearch = $('.js-search-nav-close', $module);
      self.elems.$categoryTrigger = $('.js-nav-category-trigger', $module);
      self.elems.$linkTrigger = $('.js-nav-link-trigger', $module).not(
        '.menu__content .js-nav-link-trigger'
      );
      self.elems.$headermenu = $('.site-header__menu, .site-header__wrapper');
      self.elems.$fakeTriggers = $('.js-nav-fake-target', $module);
      // Added h1 tag with title for purpose of SEO
      self.elems.$sitelogodiv = $('.site-header__menu-logo', $module);
      self.elems.$sitelogodiv.before('<h1 class="page-title hidden">' + page_title + '</h1>');
      self.updateResponsive($module);
      self.attachEventListeners(context);
      self.attachUtility(context);
      self.styleMobile(context);

      // Pushing this to onload event.
      window.addEventListener('load', function (e) {
        self.loadLazyImages();
      });
    },

    attachUtility: function (context) {
      var $triggerBlock = $('.js-gnav-util', context);
      var $menuTrigger = $('.js-gnav-mobile-trigger', $triggerBlock);
      var $closeTrigger = $('.js-gnav-util-close', $triggerBlock);
      var $blockContent = $('.js-gnav-util__content', $triggerBlock);
      var $navUtilityLinks = $('.js-gnav-util-trigger', context);
      var hideContentOnDimmerHover = true;
      var self = this;

      $triggerBlock
        .off('mouseover.open block.open')
        .on('mouseover.open', function () {
          if (!hasTouch) {

            $(this).trigger('block.open');
          }
        })
        .on('block.open', function () {
          var $utilityBlock = $(this);

          var disableGnavOverlay = Drupal.settings.common.disable_gnav_signin_overlay
            ? Drupal.settings.common.disable_gnav_signin_overlay
            : false;
          if (disableGnavOverlay && $utilityBlock.hasClass('gnav-util--account')) {
            return;
          }
          // disable dropdown for account and cart on mobile and account on pc if user is logged in.
          if (
            (hasTouch &&
              isMobileWidth &&
              ($utilityBlock.hasClass('gnav-util--account') ||
                $utilityBlock.hasClass('gnav-util--cart'))) ||
            ($utilityBlock.hasClass('gnav-util--account') &&
              self.elems.$body.hasClass('elc-user-state-logged-in'))
          ) {
            return;
          }
          self.elems.$header.trigger('gnav.close');
          var $utilityBlockContent = $utilityBlock.find('.js-gnav-util__content');

          $blockContent.not($utilityBlockContent).fadeOut(200);
          if ($utilityBlockContent.hasClass('js-gnav-util__content--search')) {
            if (!isMobileWidth) {
              $utilityBlockContent.css({
                top: $utilityBlock.closest('.js-site-header--v1').get(0).getBoundingClientRect()
                  .bottom
              });
            } else {
              $utilityBlockContent.css('top', '');
            }
          }
          $utilityBlockContent.removeClass('hidden').fadeIn(animationTime);
          self.toggleContentDimmer(!$(this).next('.js-gnav-util__content').hasClass('hidden'));
        });

      $navUtilityLinks.on('click', function () {
        if ($(this).attr('href')) {
          window.location.href = $(this).attr('href');
        } else {
          $(this).parent().trigger('block.open');
        }
      });

      self.elems.$contentDimmer.once().on('mouseover', function () {
        if (hideContentOnDimmerHover) {
          $closeTrigger.trigger('click');
        }
      });

      $('.js-signin-block-toggle', self.elems.$header).on(
        'click.ignoreDimmerMouseover',
        function () {
          hideContentOnDimmerHover = false;
          $blockContent
            .off('mouseenter.enableDimmerMouseover')
            .on('mouseenter.enableDimmerMouseover', function () {
              hideContentOnDimmerHover = true;
            });
        }
      );

      // hamburger adds a body class so we can toggle main nav
      $menuTrigger.on('click', function (event) {
        event.preventDefault();
        $blockContent.addClass('hidden');
        self.elems.$body.toggleClass('mobile-gnav-active');
      });
      // content block close
      // js-gnav-util-close
      $closeTrigger.on('click', function (event) {
        event.preventDefault();
        $('.js-predictive-search-term').val('');
        $(this).closest('.gnav-util').find('.js-gnav-util__content').fadeOut(200);
        self.toggleContentDimmer(false);
      });
    },

    closeUtility: function () {
      var self = this;

      $('.js-gnav-util__content', self.elems.$header).removeClass('active').fadeOut(200);
    },

    toggleContentDimmer: function (activate) {
      var self = this;

      self.elems.$contentDimmer.toggleClass('active active--gnav', activate);
    },

    toggleMenuDimmer: function (activate) {
      var self = this;

      self.elems.$menuDimmer.toggleClass('active active--gnav', activate);
    },

    // This function adjusts the variables on window resize
    updateResponsive: function ($module) {
      var self = this;
      var newBreakpoint = Unison.fetch.now().name;

      // Close everything if we have a new breakpoint
      if (newBreakpoint !== bp) {
        bp = newBreakpoint;
        isMobileWidth = bp !== 'landscape';
        self.elems.$header.trigger('gnav.close');
      }

      if (!isMobileWidth) {
        $(window).off('scroll.min-nav').on('scroll.mini-nav', _.debounce(function () {
          self.closeUtility();
          self.toggleContentDimmer(false);
        }, 5));
      }

      if ($module?.length && $module?.hasClass('js-sticky-nav')) {
        var $staticHeader = $('.js-static-header-nav');
        var bottomOfHeader = $staticHeader.outerHeight();      
        var lastScrollTop = 0;

        $(window).off('scroll.min-nav').on('scroll.mini-nav', _.debounce(function () {
          var scrollTop = $(window).scrollTop();
          var stickyNavActive = scrollTop > bottomOfHeader;

          if (scrollTop > lastScrollTop && stickyNavActive) {
            $staticHeader.css({"top": "-" + bottomOfHeader + "px"});
            self.elems.$body.removeClass('sticky-nav-active');
          } else if (scrollTop === 0) {
            self.elems.$body.removeClass('sticky-nav-active');
            $staticHeader.removeAttr('style');
          } else if (scrollTop < lastScrollTop) {
            $staticHeader.removeAttr('style');
            self.elems.$body.addClass('sticky-nav-active');
          }

          lastScrollTop = scrollTop;
        }, 50));
      }
    },

    attachEventListeners: function (context) {
      var self = this;

      // Global Events
      MantleWindow.onResize(function () {
        // Refresh
        self.updateResponsive();
      }, 'gnav');

      self.elems.$header.off('gnav.open').on('gnav.open', function () {
        self.elems.$menu.addClass('active');
        self.toggleMenuDimmer(true);
        if (isMobileWidth) {
          self.elems.$headermenu.addClass('menu-position');
          $('html, body').addClass('no-scroll');
        }
      });

      self.elems.$header.off('gnav.close').on('gnav.close', function () {
        // Close all active categories
        self.closeMenus();
        // Close the gnav itself
        self.elems.$menu.removeClass('active');
        self.elems.$headermenu.removeClass('menu-position');
        $('html,body').removeClass('no-scroll');
        self.toggleMenuDimmer(false);
      });

      // Click Events
      self.elems.$hamburger.off('click.open-gnav').on('click.open-gnav', function (event) {
        event.preventDefault();
        self.elems.$header.trigger('gnav.open');
      });

      self.elems.$close.off('click.close-gnav').on('click.close-gnav', function (event) {
        event.preventDefault();
        self.elems.$header.trigger('gnav.close');
        $('.js-gnav-util-trigger--cart').focus();
      });

      self.elems.$closeSearch.off('click.close-gnav').on('click.close-gnav', function (e) {
        e.preventDefault();
        self.elems.$header.trigger('gnav.close');
      });

      // Desktop only, close the cats on mouseleave
      $('.js-gnav-section-minimized', self.elems.$header)
        .off('mouseleave')
        .on('mouseleave', function (event) {
          if (hasTouch || isMobileWidth) {
            return;
          }
          var linkBox = $(this).find('.js-nav-category-trigger--top').get(0)
            ? $(this).find('.js-nav-category-trigger--top').get(0).getBoundingClientRect()
            : false;

          if (linkBox) {
            if (
              event.clientY < linkBox.top ||
              event.clientX < linkBox.left ||
              event.clientX > linkBox.right
            ) {
              self.menuAnimationQueue = setTimeout(function () {
                self.elems.$header.trigger('gnav.close');
              }, animationTime);
            }
          }
        });
      self.elems.$fakeTriggers.off('click.trigger').on('click.trigger', function (event) {
        event.preventDefault();
        self.elems.$header.trigger('gnav.close');
        $($(this).data('target')).trigger('click');
      });
      self.elems.$categoryTrigger
        .off('click.open-category mouseenter.open-category keydown.open-category')
        .on('click.open-category mouseenter.open-category keydown.open-category', function (event) {
          if (event.type === 'keydown' && event.keyCode !== 13) {
            return;
          }
          var $elem = $(this).parent().siblings('.js-nav-category-trigger-content');
          var isOpen = !$elem.hasClass('nav-hidden');
          var isTopLevel = $(this).hasClass('js-nav-category-trigger--top');
          var hasChildren = !$(this).hasClass('gnav-section__link--no-dropdown');

          if ($elem.length) {
            event.preventDefault();
          }
          self.closeUtility();
          // Redirect when clicked main menu for PC
          var redirectUrls = $(this).attr('href');
          // should redirect if clicked on a non-touch device, on a mobile layout with menu open, or on iPad if clicking the submenu items
          var shouldRedirect =
            (event.type === 'click' ||
              (event.type === 'keydown' && event.keyCode === 13 && !isTopLevel)) &&
            (!hasTouch ||
              (hasTouch && isMobileWidth && !$elem.length) ||
              (hasTouch && !isMobileWidth && !isTopLevel));

          if (shouldRedirect && redirectUrls !== '' && redirectUrls !== '#') {
            window.location.href = redirectUrls;
          }
          // Do some validation to see if we should continue
          if (!self.checkHoverEventIsValid(event)) {
            return;
          }
          // Determine if the element is already open
          if (event.type === 'mouseenter') {
            if (isOpen) {
              self.closeMenus();
            }
            // If we're opening a category on desktop, make sure any queued menu animation is cleared
            // Set our closing animation
            clearTimeout(self.menuAnimationQueue);
            clearTimeout(self.timeout);
          }
          // Open the category and pass this specific $categoryTrigger
          if (isMobileWidth || isTopLevel) {
            self.toggleCategory($(this));
          }

          self.toggleMenuDimmer(hasChildren || isMobileWidth);
          $('html').toggleClass('menu-overlay-active', hasChildren || isMobileWidth);
          self.toggleContentDimmer(false);
        });

      self.elems.$menuDimmer
        .off('click.close-overlay mouseenter.close-overlay focusin.close-overlay')
        .on(
          'click.close-overlay mouseenter.close-overlay focusin.close-overlay',
          self.dimmerCloseOverlay.bind(self)
        );
      self.elems.$contentDimmer
        .off('click.close-overlay focusin.close-overlay')
        .on('click.close-overlay focusin.close-overlay', self.dimmerCloseOverlay.bind(self));
    },

    dimmerCloseOverlay: function (event) {
      event.preventDefault();
      var self = this;
      var closeOverlay = function () {
        self.elems.$header.trigger('gnav.close');
        self.closeMenus();
        self.closeUtility();
        self.toggleMenuDimmer(false);
        self.toggleContentDimmer(false);
        $('html').removeClass('menu-overlay-active');
      };

      // Do some validation to see if we should continue
      if (!self.checkHoverEventIsValid(event)) {
        return;
      }
      // Mouseenter on desktop has a delayed animation, the click is right away
      if ((event.type === 'mouseenter' || event.type === 'focusin') && !isMobileWidth) {
        // We're going to close the overlay, but first if any of these elements are hovered over before the menu can close,
        // don't close the menu. A user might move their mouse to the overlay and then bring the mouse back to the menu.
        var $cancelOverlayCloseElems = $(
          '.js-nav-category-trigger-content, .js-nav-content-dropdown',
          self.elems.$header
        );

        $cancelOverlayCloseElems
          .off('mouseenter.cancel-close-overlay focusin.cancel-close-overlay')
          .one('mouseenter.cancel-close-overlay focusin.cancel-close-overlay', function () {
            clearTimeout(self.menuAnimationQueue);
          });
        // Set our closing animation
        self.menuAnimationQueue = setTimeout(closeOverlay, self.options.overlayHoverCloseDelay);
      } else {
        closeOverlay();
      }
    },

    // Check if the hover option is enabled and we're not on mobile
    checkHoverEventIsValid: function (event) {
      var self = this;

      // Check that its NOT a mouseenter event where enableHover is turned off or we're on mobile
      return !(event.type === 'mouseenter' && (!self.options.enableHover || hasTouch));
    },

    // This function slides down the category once a primary category is clicked
    toggleCategory: function ($category) {
      var self = this;
      var $linkWrapper = $category.parents('.js-link-wrapper');
      var $contentWrapper = $linkWrapper.siblings('.js-nav-category-trigger-content');
      var $childLinkWrappers = $('.js-link-wrapper', $contentWrapper);
      var willExpand = !$category.hasClass('active');

      if (willExpand) {
        if ($category.hasClass('js-nav-category-trigger--top')) {
          self.closeMenus();
        } else {
          self.closeMenus($category.parents('.js-menu-item-category').parent());
        }
      }

      $linkWrapper.parent().attr('aria-expanded', willExpand);
      $category.toggleClass('active', willExpand);
      if (!isMobileWidth) {
        self.timeout = setTimeout(function () {
          $contentWrapper.toggleClass('nav-hidden', !willExpand);
        }, 200);
      } else {
        $contentWrapper.toggleClass('nav-hidden', !willExpand);
      }

      // Animations
      if (isMobileWidth) {
        if (willExpand) {
          $contentWrapper.hide();
          $contentWrapper.stop().slideDown(animationTime);
          setTimeout(function () {
            // dumb but i have to do this to stop sibling slides from overflow hiding
            $contentWrapper.slideDown(0);
          }, animationTime);
        } else {
          $contentWrapper.stop().slideUp(animationTime);
          if ($childLinkWrappers.length) {
            self.closeMenus($contentWrapper);
          }
        }
      }
    },

    // This closes all menu items and hides their content including subnav and subsections
    closeMenus: function ($context) {
      var self = this;
      var $findInsideParent = $context || self.elems.$menu;
      var $content = $(
        '.js-gnav-section-minimized > .js-nav-category-trigger-content',
        $findInsideParent
      );

      // Close everything
      if (isMobileWidth) {
        $content.stop().slideUp();
      } else {
        $content.attr('style', '');
      }
      $('.js-nav-category-trigger', $findInsideParent).removeClass('active');
      $content.addClass('nav-hidden');
      $content.parent().siblings().attr('aria-expanded', 'false');
    },

    styleMobile: function (context) {
      // removes carets from categories with no subcategories on mobile
      var $subMenuCategories = $('.gnav-section__link-wrapper', context);

      $subMenuCategories.each(function () {
        var $subMenuCategory = $(this);

        if ($subMenuCategory.siblings('.gnav-section__dropdown').children().length === 0) {
          $subMenuCategory.find('.gnav-section__link').addClass('gnav-section__link--no-dropdown');
        }
      });
    },

    loadLazyImages: function () {
      var self = this;

      $('img.lazyload', self.elems.$header).each(function () {
        lazySizes.loader.unveil($(this).get(0));
      });
    }
  };
})(jQuery, window.Drupal, window.lazySizes, window.Modernizr);
